import React, { createContext, useReducer } from 'react'
import AppReducer from './AppReducer'

const initialState = {
  drawerState: false
}

// create context
export const GlobalContext = createContext(initialState)

// create provider component
export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState)

  //Actions
  function toggleDrawer(bool) {
    console.log('toggling drawer in global state', bool)

    dispatch({
      type: 'TOGGLE_DRAWER',
      payload: bool
    })
  }

  return (
    <GlobalContext.Provider
      value={{
        drawerState: state.drawerState,
        toggleDrawer
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}
