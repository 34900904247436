import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import {
  createMuiTheme,
  ThemeProvider,
  StylesProvider
} from '@material-ui/core/styles'

const outerTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#354A57'
    },
    secondary: {
      main: '#DCD4CA'
    }
  }
})

ReactDOM.render(
  <StylesProvider injectFirst>
    <ThemeProvider theme={outerTheme}>
      <App />
    </ThemeProvider>
  </StylesProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
