import React from 'react'
import './Showcase.css'
//updated
export const Showcase = () => {
  return (
    <header id='showcase'>
      <div className='showcase-content'>
        <div className='showcase-text'>
          <h1 className='l-heading'>Caleb Griffith</h1>
          <p className='lead'>
            Lead Software Engineer (JavaScript, React, NodeJS, and much more)
          </p>
        </div>
        <div className='showcase-text-bottom'>
          <p className='lead'>
            "In the same way, let your light shine before others..."
          </p>
        </div>
      </div>
    </header>
  )
}
