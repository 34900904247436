import React from 'react'
import { Showcase } from './components/Sections/Showcase/Showcase'
import { Header } from './components/Header/Header'
import { TempDrawer } from './components/Drawer/Drawer'
import { AboutMe } from './components/Sections/AboutMe'
import { WhyMe } from './components/Sections/WhyMe'
import { SampleWork } from './components/Sections/SampleWork/SampleWork'
import './App.css'
import { GlobalProvider } from './context/GlobalState'

function App() {
  return (
    <div className="App">
      <GlobalProvider>
        <Header></Header>
        <Showcase></Showcase>
        <AboutMe></AboutMe>
        <WhyMe></WhyMe>
        <SampleWork></SampleWork>
        <TempDrawer></TempDrawer>
      </GlobalProvider>
    </div>
  )
}

export default App
