import React from 'react'
import './WhyMe.css'

export const WhyMe = () => {
  return (
    <div>
      <div id='why'>
        <h1>Why Me</h1>
        <p>
          As mentioned above, I have a true passion for web development and
          engineering. I'm not someone who settles for mediocrity in what I do.
          I want to excel at whatever I'm working-on and I love to mentor/teach
          others what I've learned.
        </p>
        <br></br>
        <p>
          I'm a leader amongst my peer group as well. I'm not someone seeking to
          climb the corporate ladder but rather I consider myself more of a
          consensus builder and servant leader. I have held team lead, product
          owner, dev lead, technical lead and feature lead responsibilities
          throughout my career and thrive in a role where I do a combination of
          leading and development.
        </p>
      </div>
    </div>
  )
}
