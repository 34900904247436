import React from 'react'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import sawmill_thumb from '../../../assets/sawmill_thumb.png'
import bbq_thumb from '../../../assets/bbq1.png'
import port_thumb from '../../../assets/portfolio_thumb.png'

import './SampleWork.css'
import Flippy, { FrontSide, BackSide } from 'react-flippy'

const samples = [
  {
    thumbnail: sawmill_thumb,
    description: 'Vue/Vuetify/Firebase',
    demoUrl:
      'https://deploy-preview-14--affectionate-curie-94c2d9.netlify.app/',
    githubUrl: 'https://github.com/calebg84/new_sawmill_site',
  },
  {
    thumbnail: bbq_thumb,
    description: 'HTML/CSS',
    demoUrl: 'https://fervent-mccarthy-0e917b.netlify.com/index.html/',
    githubUrl: 'https://github.com/calebg84/bigstixbbq',
  },
  {
    thumbnail: port_thumb,
    description: 'React/Material-UI     (current page)',
    demoUrl: false,
    githubUrl: 'https://github.com/calebg84/portfolio-site',
  },
]

const flipCard = (sample) => {
  return (
    <Flippy
      flipOnHover={true} // default false
      flipOnClick={true} // default false
      flipDirection='horizontal'
    >
      <FrontSide>
        <div className='image-contain'>
          <img
            src={sample.thumbnail}
            alt={sample.description}
            className='thumb'
          />
        </div>
      </FrontSide>
      <BackSide style={{ backgroundColor: '#9AB6C2' }}>
        <Card className='card-back'>
          <div className='inner-card-back'>
            <Typography gutterBottom variant='h5' component='h2'>
              {sample.description}
            </Typography>
            <CardActions>
              <div className='flipcard-buttons'>
                {sample.demoUrl && (
                  <Button
                    target='_blank'
                    href={sample.demoUrl}
                    size='medium'
                    color='primary'
                    px={2}
                  >
                    Visit
                  </Button>
                )}
                <Button
                  target='_blank'
                  href={sample.githubUrl}
                  size='medium'
                  color='primary'
                  px={2}
                >
                  Github
                </Button>
              </div>
            </CardActions>
          </div>
        </Card>
      </BackSide>
    </Flippy>
  )
}

export const SampleWork = () => {
  return (
    <div id='what'>
      <h1>Samples</h1>
      <div className='flipCard-container'>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} lg={4}>
            {flipCard(samples[0])}
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            {flipCard(samples[1])}
          </Grid>
          <Grid className='card-three' item xs={12} sm={6} lg={4}>
            {flipCard(samples[2])}
          </Grid>
        </Grid>
      </div>
    </div>
  )
}
