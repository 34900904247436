import React from 'react'
import './AboutMe.css'

export const AboutMe = () => {
  return (
    <div id='who'>
      <h1>Who Am I</h1>
      <p>
        I love to build. I have a passion for making things and in 2017 I found
        out that building applications and tools was a way to combine my passion
        with my career. It's something I wish I'd discovered 10 years sooner.
      </p>
      <br></br>
      <p>
        Since 2017 I've poured any spare time I have into learning and
        understanding all of the different aspects of JavaScript development.
        Front end frameworks, back end frameworks libraries, syntax, logic,
        testing, HTML, CSS, Docker, k8s and so many more topics have consumed my
        development time. Most of my Youtube channel subscriptions are to
        channels that focus on JavaScript projects and concepts. I'm very
        thankful to have found that one thing that I love and want to do for the
        rest of my life.
      </p>
      <br></br>
      <p>
        Currently, I serve in a product owner and lead UI engineer role on a
        team of around 10 engineers. I'm professionally experienced with BDD/TDD
        (puppeteer, cucumber, selenium and others), CI/CD including automated
        testing, code scanning, and automated deployments. I'm experienced
        building and maintaining Docker images. While I've done mostly front-end
        work, I do have experience writing NodeJS back ends with expressJS,
        mongoDB and a few other back end tools.
      </p>
      <br></br>
      <p>
        When I'm not coding, I spend a lot of time with family. They are, and
        will always be, the most important investment of my time. My family and
        I stay busy managing our 30-acre homestead that includes various farm
        animals, lots of timber, trails, a sawmill and a woodworking shop.
      </p>
    </div>
  )
}
