import React, { useContext } from 'react'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import EmojiPeople from '@material-ui/icons/EmojiPeople'
import CollectionsIcon from '@material-ui/icons/Collections'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import Hidden from '@material-ui/core/Hidden'
import HelpIcon from '@material-ui/icons/Help'
import { GlobalContext } from '../../context/GlobalState'
import './Drawer.css'

export const TempDrawer = () => {
  const { drawerState, toggleDrawer } = useContext(GlobalContext)
  const sections = [
    { text: 'Who Am I', section: 'who', icon: <EmojiPeople /> },
    { text: 'Why Me', section: 'why', icon: <HelpIcon /> },
    { text: 'Samples', section: 'what', icon: <CollectionsIcon /> },
    { text: 'Back to Top', section: 'showcase', icon: <ArrowUpwardIcon /> }
  ]
  const drawerAction = bool => event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }

    toggleDrawer(bool)
  }

  const sideList = () => (
    <div
      role="presentation"
      onClick={drawerAction(false)}
      onKeyDown={drawerAction(false)}
    >
      <List p={2}>
        {sections.map((i, index) => (
          <a key={index} href={`#${i.section}`}>
            <ListItem button key={i.text} p={4}>
              <Hidden smDown>
                <ListItemIcon p={4}>{i.icon || ''}</ListItemIcon>
              </Hidden>
              <ListItemText primary={i.text} />
            </ListItem>
          </a>
        ))}
      </List>
      <Divider />
    </div>
  )

  return (
    <div>
      <Drawer open={drawerState} onClose={drawerAction(false)}>
        {sideList()}
      </Drawer>
    </div>
  )
}
